.spin-loader {
  width: 50px;
  height: 50px;
  border: 6px solid transparent;
  border-radius: 50%;
  border-right-color: #17335e;
  border-bottom-color: #17335e;
  animation: spin linear 1s infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
