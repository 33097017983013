body {
  background: linear-gradient(to left, #ffe5e0, #fbfef2, #e7fcfc);
}
.checkbox {
  width: 1rem;
  height: 1rem;
}
.form-heading {
  background: #ffb600;
  color: white;
  padding: 8px;
}
