.btn:active:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.btn:focus-visible {
  outline: none;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (min-width: 992px) {
  /* lg breakpoint */
  .bg-lg-primary {
    background-color: #007bff; /* Replace with your desired color */
  }
}
