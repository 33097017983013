.step {
  font-size: 20px;
  background: #c4c4c4;
  color: #fff;
  text-align: center;
  width: 80px;
  padding: 20px 0;
  border-radius: 50%;
  box-shadow: 0px 0px 20px #b7b7b7;
  font-weight: 600;
}
.step.active {
  background: #ffb600 !important;
}
.steps .col-12 {
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: center;
  background-size: 15px 1px;
  background-repeat: repeat-x;
  padding-left: 0px;
}
.steps .col-12:last-child {
  background-image: url() !important;
}
.progress-title {
  margin-bottom: -42px;
  padding-top: 1rem;
  /* margin-top: 10px; */
  font-size: 18px;
  font-weight: 600;
}
/* .dash-line {
  border: 1px dashed blue;
  height: 1px;
  position: absolute;
  top: 49px;
  z-index: -1;
  width: 60%;
  left: 15%;
} */
