.modalContainer {
  max-width: 700px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  z-index: 2;
}
.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.modalFooter {
  display: flex;
  justify-content: end;
}
.modal-btn {
  border-radius: 4px !important;
}
.modal-heading {
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  padding: 8px;
}

@media only screen and (min-width: 768px) {
  .modalContainer {
    width: 100%;
  }
}
