.dashboard {
  background: #f9f9f9;
}
.cstm-height {
  height: 100vh;
}
.card {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
.cstm-hover:hover {
  color: white !important;
  background-color: black !important;
  cursor: pointer;
}
.icon-pos {
  position: absolute;
  top: 30px;
  right: 20px;
  color: grey;
}
