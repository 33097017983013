.text {
  font-weight: 500;
}
.text-size {
  font-size: 17px;
}
.text-spacing {
  letter-spacing: 0.5px;
}
.text-color {
  color: blue;
}
.btn {
  border-radius: 15px;
}
.suitability-bg {
  background: linear-gradient(to left, #ffe5e0, #fbfef2, #e7fcfc);
}
a {
  text-decoration: none;
  color: black;
  transition: 0.3s ease-in;
}
