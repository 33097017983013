.question {
  font-size: 16px !important;
}
.question-container {
  margin: 10px 0;
  border-radius: 10px;
}
.check-score-btn {
  border-radius: 15px;
  background-color: #087bf9;
  padding: 5px 15px;
}
.check-score-btn:focus {
  box-shadow: none;
}
.final-score {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.score-circle {
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.risk-background {
  background: linear-gradient(to left, #ffe5e0, #fbfef2, #e7fcfc);
}
.position-relative {
  position: relative;
}
.side-img {
  position: fixed;
  bottom: 0px;
  width: 400px;
}
.all-questions-container p {
  margin-bottom: 2px !important;
  font-weight: 600;
}
.all-questions-container label {
  padding: 0px 5px;
  font-size: 13px;
  margin-bottom: 0px !important;
  line-height: 0;
}
.options {
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: #6e6e6e;
}
#score-container {
  width: 130px;
  margin: auto;
  right: 45px;
}
@media only screen and (max-width: 768px) {
  .all-questions-container label {
    line-height: 20px;
  }
}
