.signup-container,
.login-container,
.password-reset-container,
.resetPasswordContainer {
  height: 100vh;
}
.logo {
  margin: 1rem;
  margin-bottom: 11px;
}
.logo > img {
  width: 10rem;
}
.form-container {
  width: 100%;
}
label {
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}
.cstm-label {
  font-size: 15px;
  line-height: 20px;
}
.input-field {
  width: 85%;
}
.input-area {
  background-color: rgb(248, 249, 253);
}
.email-icon {
  right: 10px;
}

/* ForgotPassword Page CSS */

.forgotpass-container {
  width: 500px;
}

/* ResetPassword */
.cstm-width {
  width: 40%;
}
/* Admin-css */
.admin-login-container {
  height: 100vh;
  background-color: #3861c0;
}
.admin-form-container {
  width: 35%;
}
@media only screen and (max-width: 768px) {
  .form-container {
    height: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .form-container {
    width: 350px;
    border-radius: 10px;
  }
  .cstm-img-width {
    width: 400px;
  }
}
@media only screen and (min-width: 992px) {
  .form-container {
    width: 450px;
    border-radius: 10px;
  }
  .cstm-img-width {
    width: 500px;
  }
}
