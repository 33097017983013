.client-onboarding-container,
.client-details {
  height: 100vh;
}
.onboarding-table {
  font-size: 16px;
}
.onboarding-table thead th {
  min-width: 90px;
}
.onboarding-table tbody td {
  height: 40px;
}
.cstm-big-width {
  min-width: 110px !important;
}
.cstm-small-col {
  min-width: 60px !important;
}
.search-container {
  width: 500px;
  margin: auto;
}
.text-success {
  color: green !important;
}
.action-icon {
  cursor: pointer;
}
