.responsive-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  padding: 12px;
  background-color: #1d60ac;
  overflow-x: hidden;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
}
.collapse-sidebar {
  transform: translateX(0%);
}
.moneydhan-logo {
  width: 7rem;
}
@media only screen and (min-width: 992px) {
  .moneydhan-logo {
    width: 8rem;
  }
}
